import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MetaWp from "../components/meta-wp"
import Layout from "../components/layout"
import ContentGrid from "../components/content-grid"

const DefaultTagPostListing = ({ data }) => {
  const {
    wp,
    allPosts,
    allPages,
    metaWp,
    site: { siteMetadata },
    wordpressAcfOptions: { globalMeta },
  } = data

  return (
    <Layout>
      <MetaWp {...metaWp} global={globalMeta} defaults={siteMetadata} />

      <ContentGrid
        published={true}
        type={`listing`}
        contentType={[`all`]}
        allPages={allPages}
        allPosts={allPosts}
        categories={[]}
        numberOfItems={`all`}
        title={wp.name}
        subTitle={`Tagg`}
        backgroundColor={`white`}
        tags={[{ ...wp }]}
        showCategories={true}
        showExcerpts={true}
        showReadMore={true}
        showImages={true}
        showTitles={true}
        sort={`published_asc`}
      />
    </Layout>
  )
}

DefaultTagPostListing.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DefaultTagPostListing

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...SiteMetaData
    }

    wordpressAcfOptions {
      ...GlobalMetaOptions
    }

    ...AllPagesFragment
    ...AllPostsFragment

    wp: wordpressTag(id: { eq: $id }) {
      id
      name
      slug
    }
  }
`
